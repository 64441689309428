import * as React from 'react';
import * as _ from 'lodash';
import { BaseNodeComponent } from './BaseNodeComponent.tsx';

export class MappingsNodeWidget extends BaseNodeComponent {
	render() {
		return (
			<div className='node-widget function-widget' style={{ background: this.props.node.getColor() }}>
				<div className='title'>
					<div className='title-name'>
						<div >{this.props.node.getName()}</div>
						<div className='node-code' onClick={() => navigator.clipboard.writeText(this.props.node.getUserData().code)}>
							{this.props.node.getUserData().code}
							<span className="tooltip">Click to copy code</span>
						</div>
					</div>
					<button className='m-2 detail-button sm' onClick={() => this.toggleDetails(this.props.node)}>details</button>
				</div>
				<div className='ports'>
					<div className='ports-container'>{_.map(this.props.node.getInPorts(), this.generatePort)}</div>
					<div className='ports-container'>{_.map(this.props.node.getOutPorts(), this.generatePort)}</div>
				</div>
				<div hidden={this.props.node.needsToHide()}>
					<div className='general-container'>
						<table>
							<tbody>
								<tr><td>Mofifiable</td><td><input type='checkbox' disabled checked={Boolean(this.props.node.getUserData().modifiable)} /></td></tr>
								<tr><td>default value</td><td><input value={this.props.node.getUserData()['default-value']} disabled /></td></tr>
								<tr><td>Number of values</td><td><input type='number' disabled value={this.props.node.getUserData().valueList.length} /></td></tr>
							</tbody>
						</table>
					</div>
					<div className='mapping-values overflow' hidden={!this.hasValues()}>
						<table>
							<tbody>
								<tr><th colSpan={2}>MAPPING VALUES</th></tr>
								{/* <tr><th>name</th><th>value</th></tr> */}
								{this.getValues().map((value) => (
									<tr key={value.key}><td>{value.key}</td><td>{value.value.toString()}</td></tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
