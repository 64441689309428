import * as React from 'react';
import * as _ from 'lodash';
import { BaseNodeComponent } from './BaseNodeComponent.tsx';

export class FunctionNodeWidget extends BaseNodeComponent {
	render() {
		return (
			<div className='node-widget function-widget' style={{ background: this.props.node.getColor() }}>
				<div className='title'>
					<div className='title-name'>
						<div >{this.props.node.getName()}</div>
						<div className='node-code' onClick={() => navigator.clipboard.writeText(this.props.node.getUserData().code)}>
							{this.props.node.getUserData().code}
							<span className="tooltip">Click to copy code</span>
						</div>
					</div>
					<button className='m-2 detail-button sm' onClick={() => this.toggleDetails(this.props.node)}>details</button>
				</div>
				<div className='description'>{this.props.node.getUserData().description}</div>
				<div className='ports'>
					<div className='ports-container'>{_.map(this.props.node.getInPorts(), this.generatePort)}</div>
					<div className='ports-container'>{_.map(this.props.node.getOutPorts(), this.generatePort)}</div>
				</div>
				<div hidden={this.props.node.needsToHide()}>
					<table>
						<tbody>
							<tr><td>Class</td><td>{this.props.node.getUserData().class}</td></tr>
							<tr><td>Mofifiable</td><td><input type='checkbox' disabled checked={Boolean(this.props.node.getUserData().modifiable)} /></td></tr>
						</tbody>
					</table>

					<div className='variables' hidden={!this.hasVariables()}>
						<table>
							<tbody>
								<tr><th colSpan={2}>VARIABLES</th></tr>
								{this.getVariables().map((variable) => (
									<tr key={variable.name}><td>{variable.name}</td><td>{variable.value.toString()}</td></tr>
								))}
							</tbody>
						</table>
					</div>
					<textarea
						className="transformer-textarea overflow"
						id="exampleFormControlTextarea1"
						value={this.props.node.getUserData().script.decodedcontent}
						disabled
					/>
				</div>
			</div>
		);
	}
}
