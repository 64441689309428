import * as React from 'react';
import * as _ from 'lodash';
import { BaseNodeComponent } from './BaseNodeComponent.tsx';
import eventBus from '../../../../../EventBus.js';


export class CustomNodeWidget extends BaseNodeComponent {

	componentDidMount() {
		if (this.needsToRegister) {
			this.needsToRegister = false;
			eventBus.on("node-select", (data) => {
				if (data.code === this.dataNode.code) {
					// console.log(this.node);
					this.setSelected(this.dataNode.selected);
					this.forceUpdate();
				}
			});
			eventBus.on("node-update", (data) => {
				if (data.code === this.dataNode.code) {
					this.forceUpdate();
				}
			});
		}
	}

	getDetailsButton() {
		// console.log(this.node)
		if (!['expression', 'deliverypoint', 'profile'].includes(this.getNodeType())) {
			return (
				<button className='m-2 detail-button sm' onClick={() => this.toggleDetails(this.props.node)}>details</button>
			)
		}
	}

	getNodeCode() {
		if (!['expression'].includes(this.getNodeType())) {
			return (
				<div className='node-code' onClick={() => navigator.clipboard.writeText(this.props.node.getUserData().code)}>
					{this.props.node.getUserData().code}
					<span className="tooltip">Click to copy code</span>
				</div>
			)
		}
	}


	render() {
		return (
			<div className='node-widget tunnel-shadow' style={{ background: this.props.node.getColor() }}>
				<div className='title'>
					<div className='title-name'>
						<div >{this.props.node.getName()}</div>
						{this.getNodeCode()}
					</div>
					{this.getDetailsButton()}
				</div>
				{/* <div className='description'>{this.props.node.getUserData().description}</div> */}
				<div className='ports'>
					<div className='ports-container'>{_.map(this.props.node.getInPorts(), this.generatePort)}</div>
					<div className='ports-container'>{_.map(this.props.node.getOutPorts(), this.generatePort)}</div>
				</div>
				<div hidden={this.props.node.needsToHide()}>
					<div className='variables' hidden={!this.hasRuntimeVariables()}>
						<table>
							<tbody>
								<tr><th colSpan={2}>RUNTIMEVARIABLES</th></tr>
								{/* <tr><th>name</th><th>value</th></tr> */}
								{this.getRuntimeVariables().map((variable) => (
									<tr key={variable.name}><td>{variable.name}</td><td>{variable.value.toString()}</td></tr>
								))}
							</tbody>
						</table>
					</div>
					<div className='config-parameters' hidden={!this.hasConfigParameters()}>
						<table>
							<tbody>
								<tr><th colSpan={2}>CONFIG PARAMETERS</th></tr>
								{/* <tr><th>name</th><th>value</th></tr> */}
								{this.getConfigParameters().map((parameter) => (
									<tr key={parameter.name}><td>{parameter.name}</td><td>{parameter.value.toString()}</td><td>{parameter.modifiable}</td></tr>
								))}
							</tbody>
						</table>
					</div>
					<div className='transport-parameters' hidden={!this.hasTransportParameters()}>
						<table>
							<tbody>
								<tr><th colSpan={2}>TRANSPORT PARAMETERS</th></tr>
								{/* <tr><th>name</th><th>value</th></tr> */}
								{this.getTransportParameters().map((parameter) => (
									<tr key={parameter.name}><td>{parameter.name}</td><td>{parameter.value.toString()}</td><td>{parameter.modifiable}</td></tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
